@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
body {
  font-family: "Roboto Condensed", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-height: 100vh; /* Ensure the body takes full viewport height */
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure it fills the full height of the page */
}

.main-content {
  flex-grow: 1; /* This ensures the main content takes up all available space */
}

/* Footer styling */
footer {
  background-color: #2c3e50;
  color: white;
  padding: 1rem 0;
  text-align: center;
  width: 100%;
}
/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto Condensed", sans-serif;
}

/* Header Section Styles */
.header-wrapper {
  position: relative;
  background: url(/static/media/code.0a9eae2d.jpeg) no-repeat center center;
  background-size: cover;
  height: 60vh; /* Reduced height for a more compact background */
  color: white;
}

.main-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.main-info h1 {
  color: #f9ab00;
  text-transform: uppercase;
}

.typed-text {
  font-size: 2rem;
  color: #fff;
  margin-top: 20px;
}

.btn-show-projects {
  background-color: transparent;
  border: 1px solid #f9ab00;
  text-transform: uppercase;
  padding: 0.675rem 0.75rem;
  color: #f9ab00;
  margin-top: 2rem;
  text-decoration: none;
  border-radius: 4px;
  transition: 0.3s;
}

.btn-show-projects:hover {
  background-color: #f9ab00;
  color: white;
  transform: scale(1.05);
}

/* About Me Section Styles */
.about-section {
  background-color: #f4f4f4;
  padding: 60px 0;
  margin-top: -50px; /* Adjust for a seamless transition */
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.about-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.about-card {
  background-color: white;
  padding: 20px;
  text-align: center;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative; /* Ensure child elements are positioned properly */
  margin-bottom: 20px; /* Space at the bottom */
}

.circular--landscape {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
}

.fullname {
  color: #333;
  font-size: 1.5rem;
  margin: 10px 0;
}

.job {
  color: #777;
  font-size: 1rem;
  margin-bottom: 20px;
}

.about-me {
  color: #555;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 30px;
}

.btn-contact {
  background-color: #f9ab00;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: 0.3s;
  display: block;
  margin-bottom: 20px; /* Add space below the button */
  text-align: center; /* Center the button horizontally */
}

.btn-contact:hover {
  background-color: #333;
}

.social-icons {
  list-style: none;
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}

.social-icons li {
  font-size: 1.5rem;
  color: #555;
}

.social-icons a {
  text-decoration: none;
  color: inherit;
  transition: 0.3s;
}

.social-icons a:hover {
  color: #f9ab00;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .header-wrapper {
    height: 50vh; /* Further reduce height on smaller screens */
  }

  .main-info h1 {
    font-size: 2rem;
  }

  .typed-text {
    font-size: 1.5rem;
  }

  .btn-show-projects {
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
  }

  .about-card-wrapper {
    flex-direction: column;
    padding: 20px;
  }

  .about-card {
    max-width: 90%;
  }

  .social-icons {
    grid-gap: 15px;
    gap: 15px;
  }
}


/* Navbar Styles */
.navbar {
  background-color: #2c3e50; /* Dark background */
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.navbar-brand img {
  width: 4.25rem;
  height: auto;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
  display: block;
}

.navbar-toggler {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: white;
  display: none; /* Hidden by default */
  position: absolute;
  right: 10px; /* Align the toggle button to the right */
}

.navbar-nav {
  list-style: none;
  display: flex;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.nav-item {
  position: relative;
}

.nav-link {
  text-decoration: none;
  font-size: 1rem;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.nav-link:hover {
  color: #3498db; /* Accent color on hover */
  transform: scale(1.05);
}

.nav-link:focus {
  outline: none;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .navbar-toggler {
    display: block; /* Display the toggle button on mobile screens */
  }

  .navbar-nav {
    flex-direction: column;
    align-items: flex-end; /* Align the menu to the right */
    display: none; /* Hide the menu by default */
    width: 100%;
    padding-right: 10px; /* Add some padding to the right */
  }

  .navbar-nav.show {
    display: flex; /* Show the menu when toggled */
  }

  .navbar-collapse {
    width: 100%;
  }

  .navbar-nav .nav-item {
    margin: 0.5rem 0;
  }
}

/* General styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
  color: #333;
}

.projects-section {
  padding: 60px 10%;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-out;
}

.projects-intro {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  color: #2d3a41;
  margin-bottom: 40px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 30px;
  gap: 30px;
}

/* Project list styling */
.project-list {
  background-color: #fafafa;
  border-radius: 10px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.project-list:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.image-container {
  text-align: center;
  margin-bottom: 20px;
}

.project-image {
  width: 100%;
  max-width: 280px;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.project-image:hover {
  transform: scale(1.05);
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 10px;
  color: #333;
  text-align: center; /* Centers the title */
  margin-bottom: 15px; /* Space between title and description */
}

/* Project description */
.project-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.6;
  text-align: center; /* Centers the description text */
}

/* Buttons */
.btn {
  display: inline-block;
  margin-right: 15px;
  background-color: #3498db;
  color: white;
  padding: 12px 24px;
  border-radius: 25px;
  font-size: 1rem;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  border: none;
}

.btn:hover {
  background-color: #2980b9;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .projects-section {
    padding: 40px 5%;
  }

  .projects-intro {
    font-size: 2rem;
  }

  .project-list {
    padding: 15px;
  }

  .project-description {
    font-size: 0.95rem;
  }

  .btn {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .projects-section {
    padding: 30px 3%;
  }

  .projects-intro {
    font-size: 1.8rem;
  }

  .project-list {
    padding: 10px;
  }

  .project-description {
    font-size: 0.9rem;
  }

  .btn {
    font-size: 0.85rem;
    padding: 8px 16px;
  }
}
/* General styles */
.contact-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 10%;
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 80px auto 0; /* Increased margin-top to push the contact section down */
}

.contact-intro {
  text-align: center;
  margin-bottom: 30px;
}

.contact-intro h1 {
  font-size: 2.5rem;
  color: #333;
}

.contact-intro p {
  font-size: 1.1rem;
  color: #555;
}

/* Contact form styles */
.contact-form {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.contact-form-input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  margin: 5px 0;
}

.contact-form-input:focus {
  border-color: #3498db;
  outline: none;
}

.form-submit {
  display: flex;
  justify-content: center;
}

.form-button {
  background-color: #3498db;
  color: white;
  padding: 12px 24px;
  font-size: 1.1rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-button:hover {
  background-color: #2980b9;
}

/* Social Media Links */
.social-links {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 30px;
}

.social-icons {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  list-style: none;
  padding: 0;
}

.social-icon {
  font-size: 2rem;
  transition: transform 0.3s ease;
}

/* LinkedIn icon */
.social-links a.linkedin-icon {
  color: #0077b5; /* LinkedIn's official color */
}

.social-links a.linkedin-icon:hover {
  transform: scale(1.2); /* Slight scale effect on hover */
}

/* GitHub icon */
.social-links a.github-icon {
  color: #181717; /* GitHub's official color */
}

.social-links a.github-icon:hover {
  transform: scale(1.2); /* Slight scale effect on hover */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .contact-section {
    padding: 30px 5%;
    margin-top: 100px; /* Increased margin-top for smaller screens */
  }

  .contact-intro h1 {
    font-size: 2rem;
  }

  .contact-form-input {
    max-width: 100%;
  }
}

/* Very small screen sizes (e.g., mobile phones in portrait mode) */
@media (max-width: 480px) {
  .contact-section {
    padding: 20px 5%;
    margin-top: 120px; /* Ensure extra space on very small screens */
  }

  .contact-intro h1 {
    font-size: 1.8rem;
  }

  .contact-form-input {
    padding: 8px;
  }
}


#footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #2c3e50; /* Same color as navbar */
  color: white;
  padding: 20px 0;
  text-align: center;
  z-index: 100;
}

#footer .container {
  max-width: 1200px;
  margin: 0 auto;
}

#footer p {
  font-size: 1rem;
  margin: 5px 0;
}

#footer i {
  color: #f39c12; /* Smile icon color */
  margin-left: 5px;
}

/* Ensuring the footer sticks at the bottom */
body, html {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

#footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #2c3e50; /* Same as navbar */
  color: white;
}

