/* General styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
  color: #333;
}

.projects-section {
  padding: 60px 10%;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-out;
}

.projects-intro {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  color: #2d3a41;
  margin-bottom: 40px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 30px;
}

/* Project list styling */
.project-list {
  background-color: #fafafa;
  border-radius: 10px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.project-list:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.image-container {
  text-align: center;
  margin-bottom: 20px;
}

.project-image {
  width: 100%;
  max-width: 280px;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.project-image:hover {
  transform: scale(1.05);
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 10px;
  color: #333;
  text-align: center; /* Centers the title */
  margin-bottom: 15px; /* Space between title and description */
}

/* Project description */
.project-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.6;
  text-align: center; /* Centers the description text */
}

/* Buttons */
.btn {
  display: inline-block;
  margin-right: 15px;
  background-color: #3498db;
  color: white;
  padding: 12px 24px;
  border-radius: 25px;
  font-size: 1rem;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  border: none;
}

.btn:hover {
  background-color: #2980b9;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .projects-section {
    padding: 40px 5%;
  }

  .projects-intro {
    font-size: 2rem;
  }

  .project-list {
    padding: 15px;
  }

  .project-description {
    font-size: 0.95rem;
  }

  .btn {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .projects-section {
    padding: 30px 3%;
  }

  .projects-intro {
    font-size: 1.8rem;
  }

  .project-list {
    padding: 10px;
  }

  .project-description {
    font-size: 0.9rem;
  }

  .btn {
    font-size: 0.85rem;
    padding: 8px 16px;
  }
}