/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto Condensed", sans-serif;
}

/* Header Section Styles */
.header-wrapper {
  position: relative;
  background: url('../../assets/code.jpeg') no-repeat center center;
  background-size: cover;
  height: 60vh; /* Reduced height for a more compact background */
  color: white;
}

.main-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.main-info h1 {
  color: #f9ab00;
  text-transform: uppercase;
}

.typed-text {
  font-size: 2rem;
  color: #fff;
  margin-top: 20px;
}

.btn-show-projects {
  background-color: transparent;
  border: 1px solid #f9ab00;
  text-transform: uppercase;
  padding: 0.675rem 0.75rem;
  color: #f9ab00;
  margin-top: 2rem;
  text-decoration: none;
  border-radius: 4px;
  transition: 0.3s;
}

.btn-show-projects:hover {
  background-color: #f9ab00;
  color: white;
  transform: scale(1.05);
}

/* About Me Section Styles */
.about-section {
  background-color: #f4f4f4;
  padding: 60px 0;
  margin-top: -50px; /* Adjust for a seamless transition */
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.about-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.about-card {
  background-color: white;
  padding: 20px;
  text-align: center;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative; /* Ensure child elements are positioned properly */
  margin-bottom: 20px; /* Space at the bottom */
}

.circular--landscape {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
}

.fullname {
  color: #333;
  font-size: 1.5rem;
  margin: 10px 0;
}

.job {
  color: #777;
  font-size: 1rem;
  margin-bottom: 20px;
}

.about-me {
  color: #555;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 30px;
}

.btn-contact {
  background-color: #f9ab00;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: 0.3s;
  display: block;
  margin-bottom: 20px; /* Add space below the button */
  text-align: center; /* Center the button horizontally */
}

.btn-contact:hover {
  background-color: #333;
}

.social-icons {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-icons li {
  font-size: 1.5rem;
  color: #555;
}

.social-icons a {
  text-decoration: none;
  color: inherit;
  transition: 0.3s;
}

.social-icons a:hover {
  color: #f9ab00;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .header-wrapper {
    height: 50vh; /* Further reduce height on smaller screens */
  }

  .main-info h1 {
    font-size: 2rem;
  }

  .typed-text {
    font-size: 1.5rem;
  }

  .btn-show-projects {
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
  }

  .about-card-wrapper {
    flex-direction: column;
    padding: 20px;
  }

  .about-card {
    max-width: 90%;
  }

  .social-icons {
    gap: 15px;
  }
}
