
#footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #2c3e50; /* Same color as navbar */
  color: white;
  padding: 20px 0;
  text-align: center;
  z-index: 100;
}

#footer .container {
  max-width: 1200px;
  margin: 0 auto;
}

#footer p {
  font-size: 1rem;
  margin: 5px 0;
}

#footer i {
  color: #f39c12; /* Smile icon color */
  margin-left: 5px;
}

/* Ensuring the footer sticks at the bottom */
body, html {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

#footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #2c3e50; /* Same as navbar */
  color: white;
}
