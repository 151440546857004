/* General styles */
.contact-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 10%;
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 80px auto 0; /* Increased margin-top to push the contact section down */
}

.contact-intro {
  text-align: center;
  margin-bottom: 30px;
}

.contact-intro h1 {
  font-size: 2.5rem;
  color: #333;
}

.contact-intro p {
  font-size: 1.1rem;
  color: #555;
}

/* Contact form styles */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.contact-form-input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  margin: 5px 0;
}

.contact-form-input:focus {
  border-color: #3498db;
  outline: none;
}

.form-submit {
  display: flex;
  justify-content: center;
}

.form-button {
  background-color: #3498db;
  color: white;
  padding: 12px 24px;
  font-size: 1.1rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-button:hover {
  background-color: #2980b9;
}

/* Social Media Links */
.social-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.social-icons {
  display: flex;
  gap: 20px;
  list-style: none;
  padding: 0;
}

.social-icon {
  font-size: 2rem;
  transition: transform 0.3s ease;
}

/* LinkedIn icon */
.social-links a.linkedin-icon {
  color: #0077b5; /* LinkedIn's official color */
}

.social-links a.linkedin-icon:hover {
  transform: scale(1.2); /* Slight scale effect on hover */
}

/* GitHub icon */
.social-links a.github-icon {
  color: #181717; /* GitHub's official color */
}

.social-links a.github-icon:hover {
  transform: scale(1.2); /* Slight scale effect on hover */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .contact-section {
    padding: 30px 5%;
    margin-top: 100px; /* Increased margin-top for smaller screens */
  }

  .contact-intro h1 {
    font-size: 2rem;
  }

  .contact-form-input {
    max-width: 100%;
  }
}

/* Very small screen sizes (e.g., mobile phones in portrait mode) */
@media (max-width: 480px) {
  .contact-section {
    padding: 20px 5%;
    margin-top: 120px; /* Ensure extra space on very small screens */
  }

  .contact-intro h1 {
    font-size: 1.8rem;
  }

  .contact-form-input {
    padding: 8px;
  }
}
