@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

body {
  font-family: "Roboto Condensed", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-height: 100vh; /* Ensure the body takes full viewport height */
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure it fills the full height of the page */
}

.main-content {
  flex-grow: 1; /* This ensures the main content takes up all available space */
}

/* Footer styling */
footer {
  background-color: #2c3e50;
  color: white;
  padding: 1rem 0;
  text-align: center;
  width: 100%;
}